.full-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.05);
    position: fixed;
}

.lightbox {
    margin: auto auto;
}


/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(40, 40, 40);
    background-color: rgba(40, 40, 40, 90%);
}

/* Modal Content (image) */
.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}